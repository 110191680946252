<template>
  <div id="charge-employees">
    <!-- search -->
    <v-card-text class="pl-0">
      <v-card-title>Charging Details</v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
        <v-container class="fluid pl-0">
          <v-row class="align-end">
            <v-col cols="12" sm="6" md="3">
              <span class="font-weight-medium me-2">Number of Points/person:</span>
            </v-col>

            <v-col cols="12" sm="6" md="3"> </v-col>
            <v-col cols="12" sm="6" md="3">
              <span class="font-weight-medium me-2">Duration:</span>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="12" sm="6" md="3" class="align-self-start">
              <v-text-field
                v-model="chargeData.points"
                outlined
                dense
                label="#points"
                placeholder="100"
                type="number"
                hide-spin-buttons
                hide-details="auto"
                class="mb-6"
                :rules="[validators.required, validators.integerValidator]"
                height="56"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="6" class="d-flex align-items-center">
              <v-checkbox v-model="checkboxPeriod"></v-checkbox>
              <v-select
                v-model="chargeData.period"
                class="d-block"
                :items="chargingPeriods"
                :rules="checkboxPeriod ? [validators.required] : [true]"
                label="Periods of auto charging"
                outlined
                :disabled="!checkboxPeriod"
              ></v-select>
            </v-col>

            <v-col cols="12" md="3" sm="6">
              <v-text-field
                v-model="chargeData.from"
                outlined
                dense
                :rules="checkboxPeriod ? [validators.required] : [true]"
                :disabled="!checkboxPeriod"
                type="date"
                label="From"
                :min="fromDate"
                :max="toDate"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" sm="6">
              <v-text-field
                v-model="chargeData.to"
                outlined
                dense
                :rules="checkboxPeriod ? [validators.required] : [true]"
                :disabled="!checkboxPeriod"
                type="date"
                label="To"
                :min="fromDate"
                :max="toDate"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn color="primary" class="me-3" type="submit"> Charge </v-btn>
        </v-container>
      </v-form>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="companyEmps"
      :options.sync="options"
      :server-items-length="companyEmpsTotal"
      :loading="loading"
      show-select
    >
      <!-- client -->
      <template #[`item.firstname`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <router-link
              :to="{ name: 'apps-employees-view', params: { id: item.mobile_number } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              {{ item.first_name }}
            </router-link>
          </div>
        </div>
      </template>

      <template #[`item.lastname`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.last_name }}</span>
          </div>
        </div>
      </template>

      <!-- total -->
      <template #[`item.mobile`]="{ item }">
        {{ item.mobile_number }}
      </template>

      <template #[`item.verified`]="{ item }">
        {{ item.user_id ? 'Yes' : 'No' }}
      </template>
      <!-- Balance -->

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small icon v-bind="attrs" v-on="on">
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-employees-view', params: { id: item.mobile_number } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link @click="handleDelete(item.id, item.company_id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import router from '@/router'
import { integerValidator, required } from '@core/utils/validation'
import {
  mdiAccount,
  mdiBatteryPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiHistory,
  mdiPlus,
  mdiTrendingUp,
} from '@mdi/js'

import store from '@/store'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import moment from 'moment'

// store module
import employeesStoreModule from '../../employees/employeesStoreModule'

// import EmployeesListAddNew from './EmployeesListAddNew.vue'
import useEmployeesList from '../../employees/employees-list/useEmployeesList'

// composition function

export default {
  components: {
    // EmployeesListAddNew,
  },
  props: {
    employees: [],
  },
  data: () => ({}),
  methods: {
    updateValue(data) {
      this.$emit('refresh', data)
    },
  },
  setup(props, { emit }) {
    const chargeData = ref({
      employees: [],
      company_id: '',
      points: '',
      auto_charge: '',
      period: '',
      from: '',
      to: '',
    })

    const valid = ref(false)
    const form = ref(null)
    const fromDate = ref(null)
    const toDate = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const checkboxPeriod = ref(false)

    const EMPLOYEES_APP_STORE_MODULE_NAME = 'app-employees'

    // Register module
    if (!store.hasModule(EMPLOYEES_APP_STORE_MODULE_NAME)) {
      store.registerModule(EMPLOYEES_APP_STORE_MODULE_NAME, employeesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLOYEES_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEES_APP_STORE_MODULE_NAME)
    })

    const companyEmps = ref([])
    const companyEmpsTotal = ref(0)
    const isLoading = ref(false)

    onMounted(() => {
      const { id } = router.currentRoute.query
      options.value.company_id = id

      store
        .dispatch('app-employees/fetchCompany', { id: router.currentRoute.query.id })
        .then(response => {
          fromDate.value = response.data.data.from
          toDate.value = response.data.data.to
        })
        .catch(error => {
          console.log('error', error)
          if (error.response?.status === 404) {
            companyData.value = {}
          }
        })

      store
        .dispatch('app-employees/fetchEmployees', {
          options: options.value,
        })
        .then(response => {
          companyEmps.value = response.data.data.filteredData
          companyEmpsTotal.value = response.data.data.total
        })
        .catch(error => {
          console.log(error)
        })
    })

    const handleSubmit = () => {
      if (selectedTableData.value.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Please select at least one employee to charge points!',
        })
      } else if (valid.value) {
        if (checkboxPeriod.value) {
        if (moment(chargeData.value.to).isBefore(chargeData.value.from)) {
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: 'From Date Should Before To Date!',
          })
          return
        }
      } 
      isLoading.value = true
        chargeData.value.employees = []
        selectedTableData.value.forEach(employee => chargeData.value.employees.push(employee.id))
        chargeData.value.company_id = selectedTableData.value[0].company_id
        chargeData.value.auto_charge = checkboxPeriod.value ? 1 : 0

        let data

        if (chargeData.value.auto_charge) {
          data = {
            employees: chargeData.value.employees,
            company_id: chargeData.value.company_id,
            points: chargeData.value.points,
            auto_charge: chargeData.value.auto_charge,
            period: chargeData.value.period,
            from: chargeData.value.from,
            to: chargeData.value.to,
          }
        }
        if (!chargeData.value.auto_charge) {
          data = {
            employees: chargeData.value.employees,
            company_id: chargeData.value.company_id,
            points: chargeData.value.points,
            auto_charge: chargeData.value.auto_charge,
          }
        }

        store
          .dispatch('app-employees/chargeEmployees', data)
          .then(response => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: `${response.data.data.message}`,
              showConfirmButton: false,
              timer: 2500,
            }).then(() => {
              isLoading.value = false
              resetForm()
              selectedTableData.value = []
            })
          })
          .catch(error => {
            isLoading.value = false
            console.log('error', error?.response?.data?.message)
            if (error.response.data.message) {
              Swal.fire({
                icon: 'error',
                title: `Error`,
                text: `${Object.values(error.response.data.message).join('\n')}`,
              })
            }
          })
      } else {
        validate()
      }
    }

    const {
      tableColumns,
      options,
      loading,
      selectedTableData,
      resolveEmployeesStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useEmployeesList()

    const isAddNewEmployeesSidebarActive = ref(false)

    const chargingPeriods = [
      { text: 'Daily', value: 1 },
      { text: 'Weekly', value: 2 },
      { text: 'BiWeekly', value: 4 },
      { text: 'Month', value: 3 },
      { text: 'BiMonthly', value: 5 },
    ]

    const handleDelete = id => {
      store
        .dispatch('app-employees/removeEmployee', id)
        .then(() => {})
        .catch(error => {
          console.log(error)
        })
    }

    return {
      isLoading,
      companyEmpsTotal,
      form,
      fromDate,
      toDate,
      tableColumns,
      options,
      loading,
      selectedTableData,
      isAddNewEmployeesSidebarActive,
      companyEmps,
      chargingPeriods,
      resolveEmployeesStatusVariantAndIcon,
      chargeData,
      checkboxPeriod,
      valid,
      validate,
      resolveClientAvatarVariant,
      handleDelete,
      handleSubmit,
      resetForm,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiHistory,
        mdiBatteryPlus,
        mdiAccount,
        mdiFileDocumentOutline,
      },
      validators: { integerValidator, required },
    }
  },
}
</script>

<style lang="scss" scoped>
#employees-list {
  .employees-list-actions {
    max-width: 7.81rem;
  }

  .employees-list-search {
    max-width: 10.625rem;
  }

  .employees-list-status {
    max-width: 11.3rem;
  }
}
</style>

<style>
.swal2-html-container {
  white-space: pre-line;
}
</style>
